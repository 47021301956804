import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import VideoList from '../components/video/List';
import VideoApi from "../service/api/VideoApi";

export default class HomeView extends React.Component {
  constructor(props) {
    super(props)
    this.fetchData = this.fetchData.bind(this)
  }

  state = {
    videos: []
  }
  componentDidMount() {
    document.title = process.env.REACT_APP_TITLE;
    this.fetchData();
  }

  fetchData() {
    VideoApi.getList({
      query: {
        'isActive': 1,
        'order[id]': 'DESC',
        'type': 'Youtube',
        'itemsPerPage': 30,
        'page': (this.state.videos.length / 30) + 1
      }
    })
      .then((data) => {
        const videos = data['hydra:member'];
        this.setState({
          videos : this.state.videos.concat(videos)
        });
      });
  }

  render() {
    return (
      <InfiniteScroll
        dataLength={this.state.videos.length}
        next={this.fetchData}
        hasMore={true}
        loader={<h4>Loading...</h4>}
        scrollableTarget="content"
      >
        <VideoList videos={this.state.videos} showAvatar={true} showPseudo={true}></VideoList>
      </InfiniteScroll>
    )
  }
}