import React from 'react';
import {Link} from "react-router-dom";
import PlayerApi from "../service/api/PlayerApi";
import Avatar from "./player/Avatar";
import { Trans } from 'react-i18next';

export default class SideBar extends React.Component {
  state = {
    players: []
  }
  componentDidMount() {
    //
    const menu = window.document.querySelector('#menu');
    const sidebar =  window.document.querySelector('.side-bar');
    const navLink =  window.document.querySelector('.nav-link');
    menu.addEventListener('click', function () {
      sidebar.classList.toggle('show-sidebar');
    });
    navLink.addEventListener('click', function () {
      sidebar.classList.remove('show-sidebar');
    });


    PlayerApi.getList({
      query: {
        'pagination': true,
        'order[pseudo]': 'ASC',
        'nbVideo[gte]' : 20,
        'itemsPerPage': 100
      }
    })
    .then(data => {
      const players = data['hydra:member'];
      this.setState({ players });
    })
  }

  removeSidebar() {
    const sidebar =  window.document.querySelector('.side-bar');
    sidebar.classList.remove('show-sidebar');
  }


  render() {
    return (
      <div className="side-bar">
        <div className="nav">
          <Link to={`/`} className="nav-link active">
            <i className="material-icons">home</i>
            <span>Home</span>
          </Link>
          <Link to={process.env.REACT_APP_VGR_URL} className="nav-link" target="_blank">
            <i className="material-icons">videogame_asset</i>
            <span>Video Games Records</span>
          </Link>
          <Link to="https://discord.com/invite/nYsRzsZ" className="nav-link" target="_blank">
            <img className="material-icons" src="https://img.icons8.com/material/24/discord-logo--v1.png" alt="discord-logo--v1"/>
            <span>Discord</span>
          </Link>
          <hr />
          <div className="section"><Trans>common.channels</Trans></div>
          { this.state.players.map((player) =>
            <Link to={`/player/${player.id}/${player.slug}`} className="nav-link" onClick={this.removeSidebar()} key={player.id}>
                <div className="avatar">
                  <Avatar player={player} />
                </div>
                <span>{player.pseudo}</span>
            </Link>
          )}
        </div>
      </div>
    )
  }
}
