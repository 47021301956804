import React from 'react';
import { Link } from "react-router-dom";
import thumbnail from '../../assets/img/thumbnail.png';
import Avatar from "../player/Avatar";

export default class VideoList extends React.Component {
  render() {
    return (
      <div className="videos">
        { this.props.videos.map((video) =>
        <div className="video" key={video.id}>
          <div className="thumbnail">
            <Link to={`/video/${video.id}`}>
              <img src={video.thumbnail ?? thumbnail} alt="thumbnail" />
            </Link>
          </div>

          <div className="details">
            {this.props.showAvatar &&
              <div className="avatar">
                <Avatar player={video.player} />
              </div>
            }
            <div className="details-inner">
              <div className="title">
                <Link to={`/video/${video.id}`}>{video.title}</Link>
              </div>
              {this.props.showPseudo &&
                <div className="pseudo">
                  <Link to={`/player/${video.player.id}/${video.player.slug}`}>
                    {video.player.pseudo}
                  </Link>
                </div>
              }
            </div>
          </div>
        </div>
        )}
      </div>
    )
  }
}
