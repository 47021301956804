import ApiService from '../../tools/ApiService'

export default {
    getList(params = {}) {
        return ApiService.get('/api/videos', params).then(response => {
            return response.data;
        })
    },
    getVideo(id) {
        return ApiService.get('/api/videos/' + id).then(response => {
            return response.data;
        })
    },
}
