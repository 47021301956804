import axios from 'axios';
import {utils} from './utils';
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';

const ApiService = {

    /**
     * @param baseURL
     */
    init(baseURL) {
        axios.defaults.baseURL = baseURL;
    },
    /**
     *
     */
    setCache() {
        // axios.defaults.headers.common["Cache-Control"] = 'no-cache';
        // disable the default cache
        axios.defaults.adapter = throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache'}), { threshold: 2 * 1000 })
    },
    /**
     *
     */
    setHeader() {
        //axios.defaults.headers.common["Accept-Language"] = i18n.locale;
    },
    /**
     *
     * @param key
     * @param value
     */
    addheader(key, value) {
        axios.defaults.headers.common[key] =  value;
    },
    /**
     *
     */
    removeHeader() {
        axios.defaults.headers.common = {}
    },
    /**
     *
     * @param resource
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    get(resource, params= {}) {
        this.setHeader();
        // set cache
        let cache = null;
        if (params.cache) {
            cache = params.cache;
        } else {
            cache = {useCache :false};
        }
        // set query
        let queryString = '';
        if (params.query) {
            queryString = utils.getQueryString(params.query);
        }
        return axios.get(resource + queryString, cache)
    },
    /**
     *
     * @param resource
     * @param data
     * @returns {AxiosPromise<any>}
     */
    post(resource, data) {
        this.setHeader();
        return axios.post(resource, data)
    },
    /**
     *
     * @param resource
     * @param data
     * @returns {AxiosPromise<any>}
     */
    put(resource, data) {
        this.setHeader();
        return axios.put(resource, data)
    },
    /**
     *
     * @param resource
     * @returns {AxiosPromise}
     */
    delete(resource) {
        this.setHeader();
        return axios.delete(resource)
    },
    /**
     *
     * @param promises
     * @returns {Promise<unknown[]>}
     */
    all(promises) {
        this.setHeader();
        return axios.all(promises);
    },
    /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
     **/
    customRequest(data) {
        this.setHeader();
        return axios(data)
    },
}

export default ApiService
