import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSearchParams } from "react-router-dom";
import VideoList from '../components/video/List';
import VideoApi from "../service/api/VideoApi";

function withSearchParams(Component) {
  return props => <Component {...props} params={useSearchParams()} />;
}

class SearchView extends React.Component {
  constructor(props) {
    super(props)
    this.fetchData = this.fetchData.bind(this)
  }

  state = {
    videos: [],
    query: '',
  }
  componentDidMount() {
    let params = new URL(document.location).searchParams;
    if (params.has('query')) {
      this.setState({ query: params.get('query') }, () => this.fetchData());
    }
  }

  componentDidUpdate(prevProps) {
    let params = new URL(document.location).searchParams;
    if (params.has('query') && this.state.query != params.get('query')) {
      this.setState({ query: params.get('query'), videos : [] }, () => this.fetchData());
    }
  }

  fetchData() {
    document.title = 'Search' + ' - ' + process.env.REACT_APP_TITLE;
    VideoApi.getList({
      query: {
        'isActive': 1,
        'type': 'Youtube',
        'title': this.state.query,
        'itemsPerPage': 30,
        'page': (this.state.videos.length / 30) + 1
      }
    })
      .then(data => {
        const videos = data['hydra:member'];
        this.setState({
          videos : this.state.videos.concat(videos)
        });
      })
  }

  render() {
    return (
        <InfiniteScroll
          dataLength={this.state.videos.length}
          next={this.fetchData}
          hasMore={true}
          loader={<h4>Loading...</h4>}
          scrollableTarget="content"
        >
          <VideoList videos={this.state.videos} showAvatar={true} showPseudo={true}></VideoList>
        </InfiniteScroll>
    )
  }
}

export default withSearchParams(SearchView);
