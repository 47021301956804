import React from 'react';

export default class Avatar extends React.Component {

  render() {
    return (
      <img src={process.env.REACT_APP_ROOT_API + "/users/" + this.props.player.id + "/avatar"} alt={this.props.player.pseudo} />
    )
  }
}
