import { RouteObject } from "react-router-dom";
import { Outlet, Link, useRoutes } from "react-router-dom";
import Home from './views/Home'
import Search from './views/Search'
import VideoIndex from './views/video/Index'
import PlayerIndex from './views/player/Index'
import ApiService from './tools/ApiService'
import './assets/sass/styles.scss'
import React from "react";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import {Trans} from "react-i18next";
import {usePageTracking} from "./tools/tracking/usePageTracking";


// Set the base URL of the API
ApiService.init(process.env.REACT_APP_ROOT_API);
ApiService.setHeader();
ApiService.setCache();

const App = () => {
    usePageTracking();

    let routes: RouteObject[] = [
        {
            path: "/",
            element: <Layout/>,
            children: [
                {index: true, element: <Home/>},
                {
                    path: "/results",
                    element: <Search />,
                },
                {
                    path: "/video/:id",
                    element: <VideoIndex />,
                },
                {
                    path: "/player/:id/:slug",
                    element: <PlayerIndex />,
                },
                {path: "*", element: <NoMatch/>},
            ],
        },
    ];

    let element = useRoutes(routes);

    return (
    <div className="App">
        {element}
    </div>
  );
}

function Layout() {
  return (
    <div>
      {(process.env.REACT_APP_ENV === 'test') &&
        <div id="environment-alert">
          <Trans>env.warning</Trans>
        </div>
      }
      <Header />
      <main>
        <SideBar />
        <div id="content">
          <Outlet />
        </div>
      </main>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>It looks like you're lost...</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;



