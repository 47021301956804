import React from 'react';
import PlayerApi from "../../service/api/PlayerApi";
import {useParams} from "react-router-dom";
import banner from '../../assets/img/banner.png';
import Avatar from "../../components/player/Avatar";
import VideoApi from "../../service/api/VideoApi";
import VideoList from "../../components/video/List";
import { Trans } from 'react-i18next';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class PlayerIndex extends React.Component {
  state = {
    player: {
      id: null,
      pseudo: null,
      rankMedal: 0,
      rankPointGame: 0,
      nbChart: 0
    },
    videos: []
  }
  componentDidMount() {
    this.fetchData(this.props.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.id !== prevProps.params.id) {
      this.fetchData(this.props.params.id);
    }
  }

  fetchData(id) {
    PlayerApi.getPlayer(id)
      .then(data => {
        this.setState({player: data});
        document.title = data.pseudo + ' - ' + process.env.REACT_APP_TITLE;
      });
    VideoApi.getList({
      query: {
        'pagination': true,
        'isActive': 1,
        'order[id]': 'DESC',
        'type': 'Youtube',
        'player': id,
        'itemsPerPage': 30
      }
    })
      .then(data => {
        const videos = data['hydra:member'];
        this.setState({ videos });
      })
  }

  render() {
    return (
      <div id="player-index">
        <div className="header">
          <div className="banner">
            <img src={banner} alt="banner" />
          </div>
          <div className="channel-container">
            <div className="avatar">
              <Avatar player={this.state.player} />
            </div>
            <div className="channel-stats">
              {this.state.player.pseudo}&nbsp;
              [
               {this.state.player.nbChart} scores,&nbsp;
               <Trans>ranking.medals</Trans>: {this.state.player.rankMedal},&nbsp;
               <Trans>ranking.points</Trans>: {this.state.player.rankPointGame}
              ]
            </div>
          </div>
        </div>
        <div className="tabs-container">
          <h5><Trans>common.videos</Trans></h5>
        </div>
        <div className="content">
          <VideoList videos={this.state.videos} showAvatar={false} showPseudo={false}></VideoList>
        </div>
      </div>
    )
  }
}

export default withParams(PlayerIndex);
