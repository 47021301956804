import ApiService from '../../tools/ApiService'

export default {
    getList(params = {}) {
        return ApiService.get('/api/players', params).then(response => {
            return response.data;
        })
    },
    getPlayer(id, params = {}) {
        return ApiService.get('api/players/' + id, params).then(response => {
            return response.data;
        })
    },
}
