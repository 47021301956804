const getNestedValue = (obj, path) => {
    path = path.split('.')
    let res = obj
    for (let i = 0; i < path.length; i++) {
        res = res[path[i]]
    }
    return res
}

const getQueryParams = (url) => {
    let uri = url.split('?');
    if (uri.length === 2)
    {
        let vars = uri[1].split('&');
        let getVars = {};
        let tmp = '';
        vars.forEach(function(v){
            tmp = v.split('=');
            if(tmp.length === 2)
                getVars[tmp[0]] = tmp[1];
        });
        return getVars;
    }
}

const getQueryString = (obj) => {
    let queryString = '';
    queryString = Object.keys(obj).map(key => {
        if (obj[key].constructor === Array) {
            let theArrSerialized = ''
            for (let singleArrIndex of obj[key]) {
                theArrSerialized = theArrSerialized + key + '[]=' + singleArrIndex + '&'
            }
            return theArrSerialized
        } else {
            return key + '=' + obj[key] + '&'
        }
    }).join('');
    return '?' + queryString;
}

const sortIntegerArray = (array) => {
    function compareNumbers(a, b)
    {
        return a - b;
    }
    return array.sort(compareNumbers);
}

export const utils = { getQueryParams, getNestedValue , getQueryString, sortIntegerArray}
