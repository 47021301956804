import React from 'react';
import Search from './Search';

export default class Header extends React.Component {

  render() {
    return (
      <header className="header">
        <div className="logo left">
          <i id="menu" className="material-icons">menu</i>
          <img src="/yt-vgr.png" alt="logo" />
        </div>

        <Search />

        <div className="icons right">
          <i className="material-icons display-this">account_circle</i>
        </div>
      </header>
    )
  }
}
