import React from 'react';

export default class VideoPlayer extends React.Component {

  render() {
    return (
      <div className="ratio ratio-16x9">
        <iframe
          src={"https://www.youtube.com/embed/" + this.props.videoId + "?rel=0"}
          title="YouTube video"
          allowFullScreen>
        </iframe>
      </div>
    )
  }
}
