import React from 'react';
import { withRouter } from "../tools/withRouter";


class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = {query: ''};

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }


  handleChange(event) {
    this.setState( { query: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.navigate('/results?query=' + this.state.query);
  }

  render() {
    return (
      <div className="search center">
        <form onSubmit={this.handleSubmit}>
          <input type="text" placeholder="Search" value={this.state.query} onChange={this.handleChange}  />
          <button><i className="material-icons">search</i></button>
        </form>
      </div>
    )
  }
}

export default withRouter(Search);
