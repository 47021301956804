import React from 'react';
import VideoApi from "../../service/api/VideoApi";
import {Link, useParams} from "react-router-dom";
import VideoPlayer from "../../components/video/Player";
import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Avatar from "../../components/player/Avatar";
import VideoList from "../../components/video/List";
import {Trans} from "react-i18next";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class VideoIndex extends React.Component {

  state = {
    video: {
      id: null,
      videoId: null,
      title: '',
      viewCount: 0,
      player: {
        pseudo: '',
      }
    },
    'videos': []
  }

  componentDidMount() {
    this.fetchData(this.props.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.id !== prevProps.params.id) {
      this.fetchData(this.props.params.id);
    }
  }

  fetchData(id) {
    VideoApi.getVideo(id)
      .then(data => {
        this.setState({ video: data });
        document.title = data.title + ' - ' + process.env.REACT_APP_TITLE;
      })

    VideoApi.getList({
      query: {
        'isActive': 1,
        'order[id]': 'DESC',
        'type': 'Youtube',
        'itemsPerPage': 10
      }
    })
      .then(data => {
        const videos = data['hydra:member'];
        this.setState({ videos });
      })
  }

  render() {
    return (
      <div id="video-index">
        <div className="primary">
          <div className="primary-inner">
            <Container className="player">
              <VideoPlayer videoId={this.state.video.videoId} />
            </Container>
            <div className="below">
              <div className="title">
                {this.state.video.title}
              </div>
              <div className="channel-container">
                <div className="avatar">
                  <Avatar player={this.state.video.player} />
                  <Link to={`/player/${this.state.video.player.id}/${this.state.video.player.slug}`}>
                    {this.state.video.player.pseudo}
                  </Link>
                </div>
              </div>
              <div className="description">
                <div className="description-inner">
                  <div className="info-container">
                    {this.state.video.viewCount} <Trans>common.views</Trans>
                  </div>
                  <div className="description-text-container">
                    {this.state.video.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="secondary">
          <div className="secondary-inner">
            <div className="tabs">
              <div className="tab selected">Tout</div>
            </div>
            <VideoList videos={this.state.videos} showAvatar={false} showPseudo={true}></VideoList>
          </div>
        </div>
      </div>
    )
  }
}

export default withParams(VideoIndex);
